import http from "../http-common";

class StripeDataService {
  async startCheckout() {
    try {
      const response = await http.post('/stripe/create-checkout-session');
      if (response.status === 200) {
        window.location.href = response.data.url;
      } else {
        throw new Error('Failed to create checkout session');
      }
    } catch (error) {
      console.error('Error during subscription checkout:', error);
      throw error;
    }
  }

  async startPortalSession() {
    const newWindow = window.open('', '_blank'); // Open a new window immediately
  
    try {
      const response = await http.post('/stripe/customer-portal');
      if (response.status === 200) {
        newWindow.location.href = response.data.url; // Update the window location
      } else {
        newWindow.close(); // Close the window if there was an error
        throw new Error('Failed to create customer portal session');
      }
    } catch (error) {
      newWindow.close(); // Close the window if there was an error
      console.error('Error during customer portal session creation:', error);
      throw error;
    }
  }
  
}

export default new StripeDataService();