import { createPinia } from "pinia";
import { defineStore } from 'pinia';

const pinia = createPinia();

export { pinia };

import BrandsDataService from '../services/BrandsDataService';

export const useBrandStore = defineStore('brand', {
  state: () => ({
    brands: [],
    loading: false,
    currentBrand: {},
    currentBrandDB: null,
    currentIndex: -1,
    prompt_input: {
        business_name: "",
        description: "",
        tone: "",
        product_info: "",
        target_audience: "",
        post_goal: ""
      },
    editedBrandDetails: [],
  }),
  getters: {
    getBrandDetails() {
      const brandDetails = Object.keys(this.currentBrand).filter(key => key !== 'id')
      .reduce((obj, key) =>({...obj, [key]: this.currentBrand[key]}), {});

      return brandDetails
    },
    filterCurrentBrandDB() {
      const keysToExclude =  ['createdAt', 'updatedAt', 'userId'];
      const existingBrand = Object.keys(this.currentBrandDB).filter(key => !keysToExclude.includes(key))
      .reduce((obj, key) => ({...obj, [key]: this.currentBrandDB[key]}), {});

      return existingBrand
    },
    getEditedBrandDetails() {
      return this.$state.editedBrandDetails
    }
  },
  actions: {
    async getBrands() {
      this.loading = true;
      await BrandsDataService.getAll()
      .then(response => {
        this.brands = response.data
        this.loading = false;
      })
      .catch(e => {
        console.log(e);
      });
    },
    async getBrand(id) {
      this.loading = true;
      await BrandsDataService.get(id)
      .then(response => {
        this.currentBrandDB = response.data;
      })
      .catch(e => {
        console.log(e);
      });
    },
    setBrands(brands) {
      this.$state.brands = brands;
    },
    setCurrentBrand(brand, index) {
      this.$state.currentBrand = {
        'id': brand.id,
        'name': brand.name,
        'description': brand.description,
        'category': brand.category,
        'tone': brand.tone,
        'website': brand.website
      };
      this.$state.currentIndex = index;
      this.$state.editedBrandDetails = this.$state.currentBrand;
      
      // Set prompt input using current brand details
      if (brand.id == 'empty') {
        for (const key in this.prompt_input) {
          //Update the corresponding prompt_input property
           this.$state.prompt_input[key] = '';
           this.$state.prompt_input.business_name = brand.name;
          }
        } else {
        for (const key in brand) {
          // Check if the property is not empty (you can customize this condition)
          if (brand[key] !== null && brand[key] !== undefined && brand[key] !== "") {
          // Update the corresponding prompt_input property
            this.$state.prompt_input[key] = brand[key];
            this.$state.prompt_input.business_name = brand.name;
            this.$state.prompt_input.product_info = '';
            this.$state.prompt_input.target_audience = '';
            this.$state.prompt_input.post_goal = '';
          }
        }
      }
    },
    setPromptInput(prompt_input) {
      this.$state.prompt_input = prompt_input;
    }
  },
  persist: true 
});
