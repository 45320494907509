import http from "../http-common";

class UsersDataService {
  getAll() {
    return http.get("/users");
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post("/auth/signup", data);
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

  login(username, password) {
    return http.post("/auth/login/password", username, password);
  }

  logOut() {
    return http.post("/auth/logout");
  }

  findByTitle(category) {
    return http.get(`/users?category=${category}`);
  }
}

export default new UsersDataService();