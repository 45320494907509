import { createRouter, createWebHistory } from 'vue-router'
import UsersDataService from '../services/UsersDataService';
import {useUserStore} from '../stores/userStore';
import {useBrandStore} from '../stores/brandStore';
import StripeDataService from '@/services/StripeDataService';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'root',
      component: () => import('../components/promptBuilder'),
      meta: {
        menu_display: null,
        menu_icon: null,
        requireAuth: false,
        navDisplay: false
      }
    },
    {
      path: '/brands',
      alias: '/brands',
      name: 'brands',
      component: () => import('../components/BrandsList'),
      meta: {
        menu_display: 'Your Brands',
        menu_icon: 'mdi-account-group',
        requireAuth: true,
        navDisplay: false
      }
    },
    {
      path: '/brands/manage',
      name: 'brand-details',
      component: () => import('../components/BrandSingle'),
      meta: {
        menu_display: 'Manage Brand',
        menu_icon: 'mdi-account',
        requireAuth: false,
        navDisplay: true
      }
    },
    {
      path: '/brands/add',
      name: 'add',
      component: () => import('../components/AddBrand'),
      meta: {
        menu_display: 'New Brand',
        menu_icon: 'mdi-briefcase-plus',
        requireAuth: true,
        navDisplay: false
      }
    },
    {
      path: '/customer-portal',
      name: 'manage-subscription',
      beforeEnter: async (to, from, next) => {
        try {
          // Hit the API logout endpoint
          StripeDataService.startPortalSession()
            // Redirect to the login page or home page
            next({ path: '/promptBuilder' });
          } catch (error) {
            console.error('Error connecting to customer portal:', error);
            // Handle error, maybe redirect to an error page or display a message
            next({ path: '/' });
          }
        },
      meta: {
        menu_display: 'Manage Subscription',
        menu_icon: 'mdi-account',
        requireAuth: false,
        navDisplay: true
      }
    },
    {
      path: '/promptBuilder',
      name: 'promptBuilder',
      component: () => import('../components/promptBuilder'),
      meta: {
        menu_display: 'Post Maker',
        menu_icon: 'mdi-notebook-multiple',
        requireAuth: false,
        navDisplay: true
      }
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: async (to, from, next) => {
        try {
          // Hit the API logout endpoint
          UsersDataService.logOut()
            //clear the store
            useUserStore().loggedIn = false;
            useUserStore().subscribed = false;
            useUserStore().postCount = 0;
            useUserStore().user = {};
            useBrandStore().brands = [];
            useBrandStore().prompt_input = {};
            useBrandStore().currentBrand = {};
            // Redirect to the login page or home page
            next({ path: '/login' });
          } catch (error) {
            console.error('Error logging out:', error);
            // Handle error, maybe redirect to an error page or display a message
            next({ path: '/' });
          }
        },
      meta: {
        menu_display: 'Logout',
        menu_icon: 'mdi-account',
        requireAuth: false,
        navDisplay: false
      }
    },
    {
      path: '/addUser',
      name: 'addUser',
      component: () => import('../components/AddUsers'),
      meta: {
        menu_display: 'New User',
        menu_icon: 'mdi-account',
        requireAuth: false,
        navDisplay: false
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../components/login'),
      meta: {
        menu_display: 'Login  ',
        menu_icon: 'mdi-account',
        requireAuth: false,
        navDisplay: false
      }
    },
    {
      path: '/brands/manage/new-brand',
      name: 'new-brand',
      component: () => import('../components/NewBrand'),
      meta: {
        menu_display: 'Login  ',
        menu_icon: 'mdi-account',
        requireAuth: false,
        navDisplay: false
      }
    },
    {
      path: '/subscription/success',
      name: 'Success',
      component: () => import('../components/SubSuccess'),
    },
    {
      path: '/subscription/cancel',
      name: 'Cancel',
      component: () => import('../components/SubCancel'),
    }
  ]
})

router.beforeEach((to, from) => {
  // instead of having to check every route record with
  // to.matched.some(record => record.meta.requiresAuth)
  if (to.meta.requireAuth && !useUserStore().loggedIn) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return {
      path: '/login',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
      backTo: from.path,
    }
  } 
})

export default router
