<template>
    <!--Dialog activator button
    <v-btn 
    color="#157347"
    class="activator-btn"
    light 
    small 
    fab 
    icon="mdi-plus-circle"
    @click="dialog=true"></v-btn>
    -->

    <v-row justify="center">
      <v-dialog
        v-model="dialogVisible"
        width="500"
      >
     

        <v-card>
          <v-card-title>
            <span class="text-h5">Add New Brand</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="new-brand-form-field"
                >
                  <v-text-field
                    label="Brand Name*"
                    variant="solo-filled"
                    v-model="brand.name"
                    required
                    :rules="formRules"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="new-brand-form-field"
                >
                  <v-text-field
                    label="Description"
                    hint="A short description of your brand. You elevator pitch if you have one"
                    variant="solo-filled"
                    v-model="brand.description"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  class="new-brand-form-field"
                >
                  <v-text-field
                    label="Category"
                    hint="E.g., food & beverage, construction, manufacturing, etc."
                    variant="solo-filled"
                    v-model="brand.category"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="new-brand-form-field">
                  <v-text-field
                    label="Brand tone and style"
                    hint="E.g., professional, fun, etc. You can also use specific people like Guy Fieri or Gordan Ramsay"
                    variant="solo-filled"
                    v-model="brand.tone"
                    color="primary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="new-brand-form-field">
                  <v-text-field
                    label="Website"
                    variant="solo-filled"
                    v-model="brand.website"
                    color="primary"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="primary"
              variant="text"
              @click="dialogVisible = !dialogVisible">
              Close
            </v-btn>
            <v-btn
              color="primary"
              variant="tonal"
              @click="saveBrand"
            >
              Create New Brand
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
    
<script>
import BrandsDataService from "../services/BrandsDataService.js";
import {useUserStore} from '../stores/userStore';
import {useBrandStore} from '../stores/brandStore';

export default {
    name: "brand-single",
    emit: ['brandResponse'],
    setup() {
        const brandStore = useBrandStore();

        return{brandStore}
    },
    data() {
    return {
        brand: {
          id: null,
          name: "",
          description: "",
          category: "",
          website: "",
          tone: ""
        },
       dialog: false,
       formRules: [
        value => !!value || 'Required.',
        value => (value || '').length <= 20 || 'Max 20 characters'
      ]
    };
    },
    props: {
      visible: {
        type: Boolean,
        required: true
      },
    },
    methods: {
        saveBrand() {
        var data = {
          name: this.brand.name,
          description: this.brand.description,
          category: this.brand.category,
          website: this.brand.website,
          tone: this.brand.tone,
          userId: useUserStore().user.id
        };
  
        BrandsDataService.create(data)
          .then(response => {
            this.brand.id = response.data.id;
            useBrandStore().getBrands();
            useBrandStore().setCurrentBrand(response.data);
            console.log('HTTP response.data - ', response.data);
            console.log('Brand Store currentBrand - ', useBrandStore().currentBrand);
            this.dialogVisible = false;
            this.brandAddSuccess = true;

          })
          .catch(e => {
            console.log(e);
          });
      }
    },
    computed: {
      dialogVisible: {
        get() {
          return this.visible;
        },
        set(value) {
          this.$emit('update:visible', value);
        }
      }
    }
};
</script>

<style>
    .new-brand-form-field {
        padding: 0px;
    }

    .v-card-actions {
        margin: 0px auto;
        margin-top: -25px;
    }

    .activator-btn {
        margin-right: 25px;
    }
</style>