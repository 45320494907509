import './assets/main.css'

//import Vue from 'vue';
import { createApp } from 'vue'
import {createPinia} from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from './router/router'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

//Vue.config.productionTip = false;

//Setting the html title element
document.title = 'George AI Social Media Manager'

const vuetify = createVuetify({
  components: {
    ...components,
  },
  directives,
  iconfont: 'mdi'
})

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

const app = createApp(App)

app.use(router);
app.use(vuetify);
app.use(pinia);

app.mount('#app')
