<template>
  <v-app>
    <v-app-bar app v-if="userStore.loggedIn" elevation="0" color="#FFF8DC" class="d-flex justify-space-between main-app-bar" :style="$vuetify.display.mobile ? 'padding: 0px 16px' : 'padding: 0px 113px'">
    
      <v-col class="d-flex align-center nav-col">
        <v-btn v-if="!userStore.loggedIn" @click="navigateTo('addUser')">Sign Up</v-btn>
        <v-btn v-if="!userStore.loggedIn" @click="navigateTo('login')">Login</v-btn>
        <v-app-bar-nav-icon class="mobile-menu" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-btn v-if="userStore.loggedIn && !userStore.subscribed && !$vuetify.display.mobile" @click="startSubCheckout.startCheckout" class="bg-indigo nav-subscribe">Subscribe</v-btn>
      </v-col>

      <v-col class="d-flex align-center justify-center nav-title">
          <a href="/" class="george-icon-a">
            <img class="george-icon" src="../public/george_social_ape_favicon.png" width="65">
          </a>
        <v-row class="d-flex flex-column toolbar-titles">
          <v-toolbar-title class="main-nav-title">George Social AI</v-toolbar-title>
          <v-toolbar-title class="main-nav-route-title">{{ currentRouteName }}</v-toolbar-title>
        </v-row>
      </v-col>

      <v-col v-if="!$vuetify.display.mobile" class="brand-menu-col d-flex justify-end">
        <v-menu v-if="brandStore.brands.length > 0">
            <template v-slot:activator="{ props }">
                <v-btn v-if="userStore.loggedIn " color="indigo" variant="outlined"  class="brands_btn fixed-width" dark v-bind="props">{{activeBrandButtonText}}</v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item class="brand-menu-item" v-for="(brand, index) in brandStore.brands" :key="index">
                    <v-list-item-title @click="brandStore.setCurrentBrand(brand, index)">{{ brand.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
        </v-menu>
        <v-btn v-if="brandStore.brands.length < 1" color="indigo" variant="outlined"  class="brands_btn" dark @click="showNewBrandForm">Add a brand</v-btn>
      </v-col>

    </v-app-bar>

   



    <v-navigation-drawer
      v-model="drawer"
      app
      color="#FFF8DC"
      :location="$vuetify.display.mobile ? 'top' : 'left'"
      temporary
    >
      <v-list>
        <v-list-item-group v-model="activeItem">
          <v-list-item
            v-for="route in filteredRoutes"
            :key="route.name"
            @click="navigateTo(route.name)"
            :prepend-icon="route.meta.menu_icon"
          >
            
            <v-list-item-content>
          <!--    <router-link :to="{name: route.name}"></router-link> -->
              <v-list-item-title>{{ route.meta.menu_display }}</v-list-item-title>
            
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <template v-slot:append>
          <div class="pa-2">
            <v-toolbar-title v-if="userStore.loggedIn" class="text-capitalize main-nav-greeting" >Logged in as {{ userStore.user.username }}</v-toolbar-title>
            <v-btn block @click="navigateTo('logout')">Logout</v-btn>
            <v-btn block v-if="userStore.loggedIn && !userStore.subscribed" class="subscribe-nav-btn" color="indigo" @click="startSubCheckout.startCheckout">Subscribe</v-btn>
          </div>
        </template>
      
    </v-navigation-drawer>

    
    <v-app-bar v-if="$vuetify.display.mobile && userStore.loggedIn" elevation="0" color="#FFF8DC" scroll-behavior="hide">
      <v-col class="brand-menu-col d-flex justify-center">
        <v-menu v-if="brandStore.brands.length > 0">
            <template v-slot:activator="{ props }">
                <v-btn v-if="userStore.loggedIn " color="indigo" variant="outlined"  class="brands_btn fixed-width" dark v-bind="props">{{activeBrandButtonText}}</v-btn>
            </template>
            <v-list>
              <v-list-item-group>
                <v-list-item class="brand-menu-item" v-for="(brand, index) in brandStore.brands" :key="index">
                    <v-list-item-title @click="brandStore.setCurrentBrand(brand, index)">{{ brand.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
        </v-menu>
        <v-btn v-if="brandStore.brands.length < 1" color="indigo" variant="outlined"  class="brands_btn" dark @click="showNewBrandForm">Add a brand</v-btn>
      </v-col>
      
    </v-app-bar>

    <v-main>

    <div class="container mt-3">
      <router-view />
    </div>
    <NewBrand ref="newBrand" :visible="dialogVisible" @update:visible="updateDialogVisbility"/> <!--v-dialog for form to add new brand-->
  </v-main>

    <v-footer color="#FFF8DC" class="footer">Made by  
      <a href="https://www.apeman.marketing?utm_campaign=footer_link" target="_blank">Apeman Marketing.</a>
       Copyright © 2024
    </v-footer>

  </v-app>
</template>

<script>
import {useBrandStore} from './stores/brandStore';
import {useUserStore} from './stores/userStore';
import NewBrand from './components/NewBrand.vue';
import StripeDataService from "./services/StripeDataService";
import { useRoute } from 'vue-router';

export default {
  name: "app",
  components: {
    NewBrand
  },
  setup() {
    const userStore = useUserStore();
    const brandStore = useBrandStore();
    const startSubCheckout = StripeDataService;

    //fetch brands
    brandStore.getBrands();
    //fetch user  
    userStore.getUser();

    return {userStore, brandStore, startSubCheckout}
  },
  computed: {
    currentBrand() {
      return useBrandStore().currentBrand;
    },
    currentIndex() {
      return useBrandStore().currentIndex;
    },
    getBrands() {
      return useBrandStore().brands;
    },
    activeBrandButtonText() {
      const activeBrand = this.currentBrand;
      if (Object.keys(activeBrand).length === 0 && this.getBrands.length > 0) {
        return 'Select a brand';
      } else {
        return activeBrand.name;
      }  
    },
    filteredRoutes() {
      return this.routes.filter(route => {
      if (route.name === 'manage-subscription' && !this.userStore.subscribed) {
        return false;
      }
      return route.meta && route.meta.navDisplay === true;
    });
    },
    currentRouteName() {
      const route = useRoute();
      return route.meta.menu_display;
    }
  },
  data() {
    return {
      brands: [],
      brand_names: [],
      prompt_input: {
          business_name: "",
          description: "",
          tone: "",
          product_info: "",
          target_audience: "",
          post_goal: ""
        },
        drawer: false,
        activeItem: null,
        routes: [],
        rail: true,
       empty_brand: {
         id: 'empty',
         name: '',
         description: '',
         category: '',
         tone: '',
         website: ''
        },
        dialogVisible: false,
      }
    },
  created () {
    this.routes = this.$router.options.routes.filter(route => route.name && route.path);
  },
  methods: {
    setPromptInputStore() {
      useBrandStore().setPromptInput(this.prompt_input);
      console.log(useBrandStore().prompt_input);

    },
    navigateTo(routeName) {
      const routeParams = { name: routeName };

      // Include the id parameter only if this.currentBrand.id is present
      if (this.currentBrand && this.currentBrand.id && routeName == 'brand-details') {
        routeParams.params = { id: this.currentBrand.id };
      }

      this.$router.push(routeParams);
          this.drawer = false;
        },
    seeUser(){
      this.user = useUserStore().user;
      this.loggedIn = useUserStore().loggedIn;
    },
    showNewBrandForm(){
        this.dialogVisible = true;
      },
      updateDialogVisbility(value) {
        this.dialogVisible = value;
      }
    }
};
</script>

<style>
.subscribe-nav-btn {
  margin-top: 15px;
}

.nav-subscribe {
  margin-left: 20px;
}

.v-main, body {
  background-color: cornsilk !important;
}
.logout-btn {
  margin-left: 150px;
}

.brand-menu-item {
  cursor: pointer;
  
}

.brand-menu-item:hover {
  background-color: rgb(245, 245, 245);
}

.george-icon, .mobile-menu {
  margin-left: 0px;
}

.main-nav-greeting {
  font-size: smaller !important;
  text-align: center;
}

.main-nav-title {
  max-width: 150px;
  display: inline-block;
  font-size: smaller !important;
  margin-top: 10px;
}

.main-nav-route-title {
  margin-top: -10px;
}

.nav-title.v-col {
  flex-basis: 1px !important;
}

.fixed-width {
  width: 215px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.brand-menu-col {
  flex: 1;
}

.toolbar-titles {
  margin: 0px !important;
}

.george-icon-a {
  margin-left: 85px;
}

.nav-col {
  padding-top: 18px !important;
}


.footer {
  display: block !important;
  text-align: center !important;
  font-size: small;
  flex: 0 0 auto !important;
}

@media (max-width: 1290px) {
  .brands_btn .v-btn__content {
    text-wrap: balance !important;
  }

  .brand-menu-col {
    max-width: 150px;
    text-wrap: balance !important;
  }

  .logout-btn {
    display: none;
  }

  .main-app-bar {
    max-width: 100%;
  }

  .fixed-width {
  width: 150px; /* Adjust the width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  flex: 0 0 auto !important;
}
        
}
</style>