import axios from 'axios';

export default axios.create({
    withCredentials: true,
    baseURL: 'https://apeman.tech/api/',
    header: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Allow-Origin': 'true'
    }
});
