import { createPinia } from "pinia";
import { defineStore } from 'pinia';

const pinia = createPinia();

export { pinia };

import UsersDataService from "../services/UsersDataService.js";
import {useBrandStore} from '../stores/brandStore';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: {},
    loggedIn: false,
    subscribed: false,
    loading: false,
    postCount: 0
  }),
  actions: {
    async getUser() {

      this.loading = true;
      
      await UsersDataService.getAll()
      .then(response => {
        if(response.data.message) { 
          console.log('User response data: ', response.data.message);
        } else {
          this.user = response.data[0];
          const stripeSubscriptionStatus = response.data[0].stripeSubscriptionStatus;
          this.subscribed = stripeSubscriptionStatus === 'active';
          this.loggedIn = true;
          this.loading = false;
        }
      })
      .catch(e => {
        console.log(e);
      });
    },
    setCurrentUser(user) {
      this.$state.user = user;
      //  this.$state.user.id = user.id;
      //this.$state.user.username = user.username;
      this.$state.loggedIn = true;
      //this.$state.user.stripeSubscriptionId = user.stripeSubscriptionId || ''; // Ensure the field is always a string
      this.$state.loggedIn = true;
      this.$state.subscribed = user.stripeSubscriptionId ? true : false;
      useBrandStore().getBrands();
    },
  },
  persist: true 
});
